import { Link } from 'react-router'
import { Row } from 'react-table'
import { Icon, Label, Popup, Image } from 'semantic-ui-react'
import { userCountColumns } from '../../../views/tenants/components/Users/UserCounts'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { RegionFlagPopup } from '../../../utils/regionFlags'
import { TeamWithSummary } from '../../../views/newCustomers/component/CustomerUsers'
import { salesforceLogo } from '../../../images/icons'
import { environmentColours } from './NewTeamsList'

export const getUsersReportColumns = () =>
  createMemoizedColumns<TeamWithSummary>([
    {
      Header: 'Team name',
      accessor: (team: TeamWithSummary) => team.team.teamName,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Link to={`/teams/${row.original.team.teamId}?tab=users`} style={{ display: 'flex' }}>
          {row.original.team.teamName}
          {row.original.team.vendor === 'salesforce' && (
            <Popup
              content="Salesforce Team. Note that this report counts a user for this Salesforce team as a user who has an active Salesforce licence.
        They may not have a Skedulo licence assigned to them. Please ensure you check the Skedulo licence report in
        Salesforce as well to get an accurate count of active users."
              trigger={
                <div>
                  <Image
                    inline={true}
                    id="salesforce-logo"
                    src={salesforceLogo.href}
                    size="mini"
                    spaced="left"
                    style={{ width: 20 }}
                  />
                  <Icon name="question circle" />
                </div>
              }
              style={{
                opacity: 0.9,
                textAlign: 'center'
              }}
              inverted
            />
          )}
        </Link>
      )
    },
    {
      Header: 'Region',
      accessor: (team: TeamWithSummary) => team.team.region,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => <RegionFlagPopup region={row.original.team.region} />
    },
    {
      Header: 'Tenant ID',
      accessor: (team: TeamWithSummary) => team.team.tenantId,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <div className="table-cell-softwrap">{row.original.team.tenantId}</div>
      )
    },
    {
      Header: 'Owner',
      accessor: (team: TeamWithSummary) => team.team.owner
    },
    {
      Header: 'Environment',
      accessor: (team: TeamWithSummary) => team.team.environment,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) =>
        row.original.team.environment !== null ? (
          <Label color={environmentColours[row.original.team.environment] ?? 'grey'} basic>
            {row.original.team.environment}
          </Label>
        ) : (
          <></>
        )
    },
    ...userCountColumns(),
    {
      Header: 'Customer ID',
      accessor: (team: TeamWithSummary) => team.team.customerId,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Link to={`/customers/${row.original.team.customerId}?tab=users`}>
          {row.original.team.customerId.substring(0, 10)}...
        </Link>
      )
    }
  ])

export const UsersReportList = ({ data, loading }: { data?: TeamWithSummary[]; loading?: boolean }) => {
  const columns = getUsersReportColumns()
  return (
    <div className="scrollable">
      <Table
        loading={loading}
        columns={columns}
        data={data || []}
        emptyMessage={'Click the Fetch data button to load the latest users report data'}
        color="blue"
        className="sticky-table"
        textAlign="left"
      />
    </div>
  )
}
