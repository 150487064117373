import { useEffect, useState } from 'react'
import { Button, Checkbox } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { CSVLink } from 'react-csv'
import { sortBy } from 'lodash'
import { PageContent } from '../../components/react/PageContent'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { useGetAllTenantsQuery } from '../../queries/GetAllTenantsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetUsersReportForTeamsQuery } from '../../queries/GetUsersReportForTeamsQuery'
import { createSuccessToast } from '../alertComponents/Alert'
import { useSearchFilterStore } from '../../context/SearchFilterStoreContext'
import { useRouterProps } from '../../router/RouterProps'
import { TeamWithSummary } from '../newCustomers/component/CustomerUsers'
import { UsersReportList } from './component/UsersReportList'

const title = 'Users Report'

export interface CsvData {
  CustomerModel?: string
  TeamName?: string
  Region: string
  TenantID: string
  Resources: number | null
  Schedulers: number | null
  Platform?: string
  Owner: string
  Environment: string
  CustomerID: string
}

export const getCsvData = (data: TeamWithSummary[] | undefined) => {
  const csvData: CsvData[] | undefined = data?.map(team => ({
    CustomerModel: team.team.newCustomerModel ? 'NEW' : 'OLD',
    TeamName: team.team.teamName,
    Platform: team.team.vendor,
    Region: team.team.region,
    TenantID: team.team.tenantId,
    Owner: team.team.owner,
    Environment: team.team.environment,
    Resources: team.summary.totalActiveResources,
    Schedulers: team.summary.totalActiveSchedulers,
    CustomerID: team.team.customerId
  }))
  return csvData || []
}

export const UsersReport = () => {
  useDocumentTitle(title)
  const { usersReportFilter, setUsersReportFilter, updateSearchParams } = useSearchFilterStore()
  const routerProps = useRouterProps()
  const { value: showToast, setValue: setShowToast } = useBoolean(false)

  const { data: teamsList, refetch, isFetching: isFetchingTeams } = useGetAllTenantsQuery(true)
  const { data: regionsInfo, isFetching: isFetchingRegions } = useGetRegionQuery()
  const { data: reportData, isFetching: isFetchingUsers } = useGetUsersReportForTeamsQuery(
    teamsList || [],
    regionsInfo || []
  )
  const isFetching = isFetchingTeams || isFetchingRegions || isFetchingUsers

  useEffect(() => {
    if (!isFetching && reportData && showToast) {
      createSuccessToast('Latest users report data successfully fetched.')
      setShowToast(false)
    }
  }, [isFetching])

  const refetchButton = () => {
    refetch()
    setShowToast(true)
  }

  const initUsersReportFilter = () => {
    const searchParams = new URLSearchParams(routerProps.location.search)

    return {
      showCustomerTeamsOnly: {
        ...usersReportFilter.showCustomerTeamsOnly,
        value: searchParams.get('showCustomerTeamsOnly')
          ? searchParams.get('showCustomerTeamsOnly') === 'true'
          : usersReportFilter.showCustomerTeamsOnly.default
      },
      showProductionTeamsOnly: {
        ...usersReportFilter.showProductionTeamsOnly,
        value: searchParams.get('showProductionTeamsOnly')
          ? searchParams.get('showProductionTeamsOnly') === 'true'
          : usersReportFilter.showProductionTeamsOnly.default
      }
    }
  }

  const [toggleValues, setToggleValues] = useState(initUsersReportFilter())

  const onToggleFilter = (filter: 'showCustomerTeamsOnly' | 'showProductionTeamsOnly') => () => {
    setToggleValues({
      ...usersReportFilter,
      [filter]: {
        ...usersReportFilter[filter],
        value: !usersReportFilter[filter].value
      }
    })
  }

  useEffect(() => {
    setUsersReportFilter({ ...usersReportFilter, ...toggleValues })
    updateSearchParams(routerProps, toggleValues, ['showCustomerTeamsOnly', 'showProductionTeamsOnly'])
  }, [toggleValues])

  useEffect(() => {
    updateSearchParams(routerProps, toggleValues, ['showCustomerTeamsOnly', 'showProductionTeamsOnly'], true)
  }, [routerProps.location.search])

  const displayTeams: TeamWithSummary[] = sortBy(
    reportData
      ?.filter(team => team.team.owner === 'Customer' || !usersReportFilter.showCustomerTeamsOnly.value)
      .filter(team => team.team.environment === 'Production' || !usersReportFilter.showProductionTeamsOnly.value) || [],
    [team => team.team.teamName]
  )
  const csvData = getCsvData(displayTeams)

  const newModelDisplayTeams = displayTeams?.filter(team => team.team.newCustomerModel)

  return (
    <PageContent>
      <h2>Users report</h2>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Toggles>
            <Checkbox
              toggle
              label="Show customer teams only"
              checked={usersReportFilter.showCustomerTeamsOnly.value}
              onClick={onToggleFilter('showCustomerTeamsOnly')}
              aria-label="Show customer teams only"
            />
            <Checkbox
              toggle
              label="Show production teams only"
              checked={usersReportFilter.showProductionTeamsOnly.value}
              onClick={onToggleFilter('showProductionTeamsOnly')}
              aria-label="Show production teams only"
            />
          </FiltersTogglesButtons.Toggles>
          <FiltersTogglesButtons.Buttons>
            {csvData.length > 0 ? (
              <CSVLink data={csvData} filename="users-report.csv">
                <Button className="form-button-sked-blue" content="Export to CSV" />
              </CSVLink>
            ) : (
              <Button className="form-button-sked-blue" content="Export to CSV" disabled />
            )}
            <Button
              className="form-button-sked-blue"
              content="Fetch data"
              onClick={() => refetchButton()}
              loading={isFetching}
              disabled={isFetching}
            />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <UsersReportList data={newModelDisplayTeams} loading={isFetching} />
      </PageContent.Main>
    </PageContent>
  )
}
